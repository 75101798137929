import './common/common.css'
import React, { Component } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from './common/components/loading'
import Header from './header/header'
import { Layout } from 'antd'

const Home = Loadable({ loader: () => import(/* webpackChunkName: "home" */'./home/home'), loading: Loading })
const Search = Loadable({ loader: () => import(/* webpackChunkName: "search" */'./search/search'), loading: Loading })
const Store = Loadable({ loader: () => import(/* webpackChunkName: "store" */'./store/store'), loading: Loading })
const Path = Loadable({ loader: () => import(/* webpackChunkName: "path" */'./path/path'), loading: Loading })
const Challenge = Loadable({ loader: () => import(/* webpackChunkName: "challenge" */'./challenge/challenge'), loading: Loading })
const Settings = Loadable({ loader: () => import(/* webpackChunkName: "settings" */'./settings/settings'), loading: Loading })
const Completions = Loadable({ loader: () => import(/* webpackChunkName: "completions" */'./completions/completions'), loading: Loading })
const Followings = Loadable({ loader: () => import(/* webpackChunkName: "followings" */'./followings/followings'), loading: Loading })
const Avatar = Loadable({ loader: () => import(/* webpackChunkName: "avatar" */'./avatar/avatar'), loading: Loading })
const Course = Loadable({ loader: () => import(/* webpackChunkName: "course" */'./course/course'), loading: Loading })
const CourseModal = Loadable({ loader: () => import(/* webpackChunkName: "course-modal" */'./course/components/modal'), loading: Loading })
const Lesson = Loadable({ loader: () => import(/* webpackChunkName: "lesson" */'./lesson/lesson'), loading: Loading })
const Review = Loadable({ loader: () => import(/* webpackChunkName: "review" */'./review/review'), loading: Loading })

class App extends Component {
    
    // Configuring for modal routes. Ref: https://reacttraining.com/react-router/web/example/modal-gallery 
    previousLocation = this.props.location

    componentWillUpdate(nextProps) {
        const { location } = this.props     
        if (nextProps.history.action !== "POP" && (!location.state || !location.state.modal)) {
            this.previousLocation = location
        }
    }

    render() {
        const { location } = this.props
        const pathname = location.pathname
        const isModal = !!(location.state && location.state.modal && (this.previousLocation !== location)) // not initial render
        const isMobileApp = pathname.substring(0, 15) === '/mobile-avatar/' || pathname.substring(0, 14) === '/mobile-store/'

        return (
            <div className="app">
                <Layout>
                    <Header />
                    <Layout className={isMobileApp ? 'mobile-main' : 'main'}>
                        <Switch location={isModal ? this.previousLocation : location}>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/customize' component={Followings} />
                            <Route exact path='/search' component={Search} />
                            <Route exact path='/completions' component={Completions} />
                            <Route exact path='/settings' component={Settings} />
                            <Route exact path='/store' component={Store} />
                            <Route exact path='/avatar' component={Avatar} />
                            <Route exact path='/mobile-store/:userId' component={Store} />
                            <Route exact path='/mobile-avatar/:userId' component={Avatar} />
                            <Route path='/review' component={Review} />
                            <Route exact path='/path/:pathId' component={Path} />
                            <Route exact path='/challenge/:pathId' component={Challenge} />
                            <Route exact path='/:courseId' component={Course} />
                            <Route exact path='/:courseId/:lessonId' component={Lesson} />
                            <Route exact path='/preview/:authorId/:courseId/:lessonId' component={Lesson} />
                            <Redirect path="*" to="/" />
                        </Switch>
                        {isModal && <Route exact path='/:courseId' component={CourseModal} />}
                    </Layout>
                </Layout>
            </div>
        )
    }
}

export default withRouter(App)
