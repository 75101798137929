import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import { reducer as formReducer } from 'redux-form'
import loadingReducer from './common/tools/loading'
import reviewReducer from './common/tools/review'

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    form: formReducer,
    loading: loadingReducer,
    review: reviewReducer
})

export default rootReducer