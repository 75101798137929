import React from 'react'

const LevelIcon = () => {
    return (
        <svg width="28px" height="28px" viewBox="0 0 172 172">
            <path fill="#11996c" d="M2.65391,86c0,-46.02344 37.32266,-83.34609 83.34609,-83.34609c46.02344,0 83.34609,37.32266 83.34609,83.34609c0,46.02344 -37.32266,83.34609 -83.34609,83.34609c-46.02344,0 -83.34609,-37.32266 -83.34609,-83.34609z" />
            <g transform="scale(0.85) translate(16, 12)">
                <path fill="white" d="M130.41094,46.93047c-6.58437,0.43672 -12.46328,0.03359 -17.60312,-0.90703c-18.07344,-3.29219 -27.11016,-12.93359 -27.37891,-13.23594c0,-0.03359 0,-0.03359 0,-0.03359l-0.03359,0.03359l-0.03359,-0.03359v0.03359c-0.40313,0.43672 -16.59531,17.7375 -49.41641,13.73984c0,37.59141 0,48.67734 0,48.67734c-0.20156,3.15781 1.74688,11.01875 7.69297,20.02188c2.18359,3.32578 4.90469,6.78594 8.26406,10.2125c7.76016,7.96172 18.91328,15.75547 34.60156,21.26484c46.72891,-19.82031 48.34141,-51.49922 48.34141,-51.49922c0,0 0,-11.08594 0,-48.67734c-1.51172,0.16797 -2.98984,0.30234 -4.43438,0.40313z" />
                <path fill="white" d="M85.42891,32.7875c0,-0.03359 0,-0.03359 0,-0.03359v0.03359l-0.03359,-0.03359v0.03359c-0.40312,0.43672 -16.59531,17.7375 -49.41641,13.73984c0,37.59141 0,48.67734 0,48.67734c-0.20156,3.15781 1.74688,11.01875 7.69297,20.02188c2.18359,3.32578 4.90469,6.78594 8.26406,10.2125c7.76016,7.96172 18.91328,15.75547 34.60156,21.26484c-0.03359,0 -0.80625,-113.61406 -1.10859,-113.91641z" />
            </g>
        </svg>
    )
}

export default LevelIcon