import { actionTypes } from 'redux-firestore'
import { SubmissionError } from 'redux-form'
import { loadUser, loadedUser } from '../common/tools/loading'
import { push } from 'connected-react-router'
import { SystemError } from '../common/tools/error'

export const login = (creds) => {
    return async (dispatch, getState, { getFirebase }) => {
        try {
            dispatch(loadUser())
            await getFirebase().auth().signInWithEmailAndPassword(creds.email, creds.password)
        } catch (error) {
            dispatch(loadedUser())
            if (error.code === "auth/argument-error") throw new SubmissionError({ _error: "Something's missing..." })
            else throw new SubmissionError({ _error: error.message })
        }
    }
}

export const signup = (creds) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        try {
            dispatch(loadUser())
            // Create the user in Firebase
            let userForFirebase = await getFirebase().auth().createUserWithEmailAndPassword(creds.email, creds.password)
            // Create the user in Firestore
            let userForFirestore = {
                name: creds.name,
                email: creds.email,
                neurons: 0,
                nutcoins: 100,
                items: [],
                guest: false,
                createdAt: getFirestore().FieldValue.serverTimestamp()
            }
            await getFirestore().collection('users').doc(userForFirebase.user.uid).set(userForFirestore)
        } catch (error) {
            dispatch(loadedUser())
            if (error.code === "auth/argument-error") throw new SubmissionError({ _error: "Something's missing..." })
            else throw new SubmissionError({ _error: error.message })
        }
    }
}

export const signout = () => {
    return async (dispatch, getState, { getFirebase }) => {
        try {
            dispatch(loadedUser())
            await getFirebase().auth().signOut()
            dispatch({ type: actionTypes.CLEAR_DATA })
            dispatch(push('/'))
        } catch (error) {
            SystemError(error)
        }
    }
}