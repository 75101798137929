import './header.css'
import React, { Component } from 'react'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withRouter, Link } from 'react-router-dom'
import CountUp from 'react-countup'
import { getLevel } from '../common/tools/level'
import processItems from '../common/tools/items'
import { signout } from '../auth/actions'
import Login from '../auth/components/login'
import Collections from './components/collections'
import BagIcon from './components/bag.icon'
import StoreIcon from './components/store.icon'
import Logo from '../common/components/logo'
import LevelIcon from './components/level.icon'
import NutcoinIcon from '../common/components/nutcoin'
import Options from './components/options'
import Button from '../common/components/button'
import { Popover, Icon, Alert } from 'antd'

export class Header extends Component {
    state = {
        shadow: false
    }

    componentDidMount() {
        if (!isServer) ReactGA.initialize('UA-132433812-1', { titleCase: false })

        window.addEventListener('scroll', this.scroll)

        this.fetchItems()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.auth !== this.props.auth && this.props.auth.uid) {
            ReactGA.set({ userId: this.props.auth.uid })
            this.fetchItems()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scroll)
    }

    scroll = () => {
        if (window.scrollY > 0 && !this.state.shadow) this.setState({ shadow: true })
        if (window.scrollY <= 0) this.setState({ shadow: false })
    }

    fetchItems = () => {
        this.props.firestore.get({
            collection: 'items',
            storeAs: 'items'
        })
    }

    render() {
        const { shadow } = this.state
        const { signout, currentUser, items, location } = this.props
        const pathname = location.pathname

        const inLesson = (pathname.match(/\//g).length === 2) && !pathname.includes('/path/')
        const inReview = (pathname.match(/\//g).length === 3) && pathname.includes('review')
        const inPreview = pathname.substring(0, 9) === '/preview/'
        const inSearch = pathname === '/search'
        const inCustomize = pathname === '/customize'
        const inHome = pathname === '/'

        const isGuest = currentUser && currentUser.guest && !currentUser.followings

        const inMobileApp = pathname.substring(0, 15) === '/mobile-avatar/' || pathname.substring(0, 14) === '/mobile-store/'
        const inDesktopApp = isServer ? false : navigator.userAgent.toLowerCase().indexOf(' electron/') > -1

        const hideHeader = inLesson || inReview || (inHome && isGuest) || (inDesktopApp && isGuest)
        const hideSide = inDesktopApp || inSearch || inCustomize

        if (inPreview) return <div className="preview-banner"><Alert message="This is a preview" showIcon={false} banner /></div>
        if (inMobileApp) return <div />
        else return (
            <div className={`header-container ${shadow ? 'header-shadow' : ''} ${hideHeader ? 'hide-header' : ''}`}>
                <div className="header">
                    {currentUser &&
                        <div className="header-left">
                            {isGuest ?
                                !hideSide && <div className="header-left-content">
                                    <Link to='/search'>
                                        <Button type="white" size="normal-circle">
                                            <div><Icon type="search" /></div>
                                        </Button>
                                    </Link>
                                </div>
                                :
                                <div className="header-left-content">
                                    <Popover
                                        overlayClassName="collections-popover"
                                        placement="bottomLeft"
                                        trigger="click"
                                        content={items && <Collections
                                            nutcoins={currentUser && currentUser.nutcoins}
                                            userItems={currentUser && currentUser.items}
                                            items={items}
                                        />}>
                                        <div className="round-button-container">
                                            <Button className="bag-button hide-in-mobile" type="white" size="large-circle">
                                                <BagIcon />
                                            </Button>
                                        </div>
                                    </Popover>
                                    <div className="round-button-container">
                                        <Link to='/store'>
                                            <Button className="store-button hide-in-mobile" type="white" size="large-circle">
                                                <StoreIcon />
                                            </Button>
                                        </Link>
                                    </div>
                                    <div className="round-button-container">
                                        <Link to='/search'>
                                            <Button type="white" size="large-circle">
                                                <div><Icon type="search" /></div>
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    <Logo />
                    {currentUser &&
                        <div className="header-right">
                            {isGuest ?
                                !hideSide && <Login />
                                :
                                <div className="header-right-content">
                                    <div className="number-container hide-in-mobile">
                                        <LevelIcon />
                                        <div className="number" style={{ color: '#11996c' }}>
                                            {currentUser && <CountUp end={getLevel(currentUser.neurons)} />}
                                        </div>
                                    </div>
                                    <div className="number-container hide-in-mobile">
                                        <NutcoinIcon small />
                                        <div className="number" style={{ color: '#f2a900' }}>
                                            {currentUser && <CountUp end={currentUser.nutcoins} />}
                                        </div>
                                    </div>
                                    <Options
                                        avatar={currentUser.avatar}
                                        signout={signout}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapState = state => ({
    auth: state.firebase.auth,
    currentUser: state.firestore.data.currentUser,
    items: processItems('collection', state.firestore.ordered.items)
})

const actions = { signout }

const isServer = !(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
)

const isMobileApp = pathname => {
    return pathname.includes('/mobile-avatar/') || pathname.includes('/mobile-store/')
}

const query = props => {
    if (isServer) return []
    if (isMobileApp(props.location.pathname)) {
        const pathname = props.location.pathname
        const userId = pathname.substring(pathname.length - 28, pathname.length)
        return [{
            collection: 'users',
            doc: userId,
            storeAs: 'currentUser'
        }]
    }
    else return [{
        collection: 'users',
        doc: props.auth.uid || 'guest',
        storeAs: 'currentUser'
    }]
}

export default withRouter(connect(mapState, actions)(firestoreConnect(query)(Header)))
