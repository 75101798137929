import React, { Component } from 'react'
import { connect } from 'react-redux'
import Auth from '../auth'
import Button from '../../common/components/button'
import { Modal } from 'antd'

export class Login extends Component {
    state = {
        visible: false
    }

    render() {
        const { visible } = this.state
        const { isLanding } = this.props

        return (
            <div className="login-button">
                <Button 
                    onClick={() => this.setState({ visible: true })} 
                    type="white" size={isLanding ? 'large' : 'normal'}>
                    LOGIN
                </Button>
                <Modal
                    className="login-modal"
                    title="Login" 
                    visible={visible}
                    onCancel={() => this.setState({ visible: false})}
                    centered footer={null}>
                    <Auth />
                </Modal>   
            </div>
        )
    }
}

export default connect()(Login)