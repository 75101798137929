import React from 'react'
import { Icon } from 'antd'

const Button = props => {
    return (
        <button 
            onClick={props.onClick} 
            className={`button ${props.type} ${props.size} ${props.className || ''}`} 
            style={props.style}
            disabled={props.disabled || props.loading}>
            {props.loading ? <Icon type="loading" /> : props.children}
        </button>
    )
}

export default Button
