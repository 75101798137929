import React from 'react'

const NutcoinIcon = props => {
    return (
        <svg width={props.big ? '80px' : props.small ? '28px' : '50px'} height={props.big ? '80px' : props.small ? '28px' : '50px'} viewBox='12 12 148 148' xmlns='http://www.w3.org/2000/svg'>
            <path fill="#f2a900" d="M156.75,85.5c0,39.34425 -31.90575,71.25 -71.25,71.25c-39.34425,0 -71.25,-31.90575 -71.25,-71.25c0,-39.34425 31.90575,-71.25 71.25,-71.25c39.34425,0 71.25,31.90575 71.25,71.25z"></path>
            <path fill="white" d="M95.82413,104.00719c0,-2.86781 -0.76594,-5.13356 -2.29781,-6.83288c-1.53187,-1.69931 -4.1325,-3.27037 -7.80544,-4.7025c-3.67294,-1.43212 -11.95219,-6.08119 -15.55744,-9.4335c-1.94869,-1.80975 -3.39506,-4.00781 -4.45312,-6.48375c-1.05806,-2.49375 -1.58531,-5.4435 -1.58531,-8.86706c0,-5.8995 1.767,-11.02237 5.00887,-15.14062c4.45313,-5.67862 12.80363,-6.23437 12.80363,-6.23437v-10.6875h7.125v10.6875c4.14675,0 12.34406,1.37869 17.17125,8.76731c4.26075,6.5265 4.20375,16.17019 4.20375,16.17019h-14.25c0,-4.20375 -0.83363,-7.19625 -2.49019,-9.26962c-1.68506,-2.08762 -4.25719,-3.13144 -7.05375,-3.13144c-2.76806,0 -4.48162,1.197 -5.985,2.82506c-1.51762,1.63163 -2.28356,3.86888 -2.28356,6.73669c0,2.65762 0.73744,4.80225 2.22656,6.40181c1.47488,1.59956 3.79762,2.88206 7.83394,4.54931c4.02206,1.68506 7.33519,3.25613 9.92156,4.74525c2.60062,1.48912 4.788,3.17419 6.56925,5.03737c1.7955,1.86319 3.15994,4.00781 4.104,6.38756c0.93337,2.40825 1.40719,5.19056 1.40719,8.379c0,5.94225 -1.78125,10.76944 -5.358,14.47444c-3.57675,3.705 -9.75413,5.88525 -16.017,6.55144v10.43813h-7.125v-10.38113c-6.90412,-0.78019 -11.89875,-3.31312 -15.68212,-7.64156c-3.80119,-4.31419 -5.69288,-10.43456 -5.69288,-17.60231h14.25c0,4.161 0.94762,7.73775 2.85356,9.95006c1.90594,2.22656 4.25719,3.34162 7.83394,3.34162c2.964,0 6.03844,-0.81938 7.76625,-2.43675c1.71,-1.64231 2.55787,-3.82612 2.55787,-6.59775z"></path>
        </svg>
    )
}

export default NutcoinIcon
