import React from 'react'

const BagIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="35px" viewBox="0 0 180 180">
            <g transform="translate(35, 25)" fillRule="nonzero">
                <path fill="#546E7A" d="M76.5,0 C70.1510417,0 65,5.05371 65,11.25 L65,26.25 C65,28.33008 66.7070332,30 68.8333333,30 C70.9446605,30 72.6666667,28.33008 72.6666667,26.25 L72.6666667,11.25 C72.6666667,9.18457125 74.3736998,7.5 76.5,7.5 C78.6113272,7.5 80.3333333,9.18457125 80.3333333,11.25 L80.3333333,26.25 C80.3333333,28.33008 82.0403665,30 84.1666667,30 C86.2779938,30 88,28.33008 88,26.25 L88,11.25 C88,5.05371 82.8339853,0 76.5,0" />
                <path fill="#546E7A" d="M35.5,0 C29.1510417,0 24,5.05371 24,11.25 L24,26.25 C24,28.33008 25.7070332,30 27.8333333,30 C29.9446605,30 31.6666667,28.33008 31.6666667,26.25 L31.6666667,11.25 C31.6666667,9.18457125 33.3736998,7.5 35.5,7.5 C37.6113272,7.5 39.3333333,9.18457125 39.3333333,11.25 L39.3333333,26.25 C39.3333333,28.33008 41.0403665,30 43.1666667,30 C45.2779938,30 47,28.33008 47,26.25 L47,11.25 C47,5.05371 41.8339853,0 35.5,0" />
                <path fill="#90A2E0" d="M106.4,60.0095099 L106.4,53 L7.6,53 L7.6,60.0095099 L0,109.225545 C0,126.771739 14.1164072,141 31.5578144,141 L82.4421894,141 C99.86875,141 114,126.771739 114,109.225545 L106.4,60.0095099 Z" />
                <path fill="#606FC4" d="M106.214286,59.2647059 L106.214286,54.4117647 L110,35 L4,35 L7.78571429,54.4117647 L7.78571429,59.2647059 C7.78571429,59.2647059 29.0951441,68 57.9464286,68 C86.782926,68 106.214286,59.2647059 106.214286,59.2647059" />
                <path fill="#5564A5" d="M58.0074565,27 C85.0614845,27 107,30.1396528 107,34.0071351 C107,37.8746174 85.0614845,41 58.0074565,41 C30.9385155,41 9,37.8746174 9,34.0071351 C9,30.1396528 30.9385155,27 58.0074565,27" />
                <path fill="#90A2E0" d="M57,23 C27.7198661,23 4,28.1510417 4,34.5 C4,40.8489583 27.7198661,46 57,46 C86.2653469,46 110,40.8489583 110,34.5 C110,28.1510417 86.2653469,23 57,23 M57,40.25 C33.797713,40.25 18.3443099,37.0455748 12.0742206,34.5 C18.3443099,31.954429 33.797713,28.75 57,28.75 C80.202287,28.75 95.6409031,31.954429 101.925783,34.5 C95.6409031,37.0455748 80.202287,40.25 57,40.25" />
                <polygon fill="#90A2E0" points="73 57 88 57 88 72 73 72"/>
                <polygon fill="#90A2E0" points="27 57 42 57 42 72 27 72"/>
            </g>
        </svg>
    )
}

export default BagIcon