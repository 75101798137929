import React from 'react'

const StoreIcon = () => {
    return (
        <div>
            <svg width="30px" height="30px" viewBox="0 0 48 48">
                <g transform="translate(0, 5)">
                    <path fill="#CFD8DC" d="M 5 19 L 43 19 L 43 38 L 5 38 Z "></path>
                    <path fill="#B0BEC5" d="M 5 38 L 43 38 L 43 42 L 5 42 Z "></path>
                    <path fill="#E3F2FD" d="M 12 24 L 36 24 L 36 42 L 12 42 Z "></path>
                    <path fill="#2196F3" d="M 13 25 L 35 25 L 35 42 L 13 42 Z "></path>
                    <path fill="#64B5F6" d="M 35 42 L 13 42 L 13 35 L 35 25 Z "></path>
                    <path fill="#D84315" d="M 27 19 C 27 20.65625 25.65625 22 24 22 C 22.34375 22 21 20.65625 21 19 C 21 17.34375 22.34375 16 24 16 C 25.65625 16 27 17.34375 27 19 "></path>
                    <path fill="#D84315" d="M 39 19 C 39 20.65625 37.65625 22 36 22 C 34.34375 22 33 20.65625 33 19 C 33 17.34375 34.34375 16 36 16 C 37.65625 16 39 17.34375 39 19 "></path>
                    <path fill="#D84315" d="M 9 19 C 9 20.65625 10.34375 22 12 22 C 13.65625 22 15 20.65625 15 19 C 15 17.34375 13.65625 16 12 16 C 10.34375 16 9 17.34375 9 19 "></path>
                    <path fill="#FF5722" d="M 40 6 L 8 6 C 6.894531 6 6 6.894531 6 8 L 6 11 L 42 11 L 42 8 C 42 6.894531 41.105469 6 40 6 "></path>
                    <path fill="#FF5722" d="M 21 11 L 27 11 L 27 19 L 21 19 Z "></path>
                    <path fill="#FF5722" d="M 37 11 L 32 11 L 33 19 L 39 19 Z "></path>
                    <path fill="#FF5722" d="M 11 11 L 16 11 L 15 19 L 9 19 Z "></path>
                    <path fill="#FFA000" d="M 33 19 C 33 20.65625 31.65625 22 30 22 C 28.34375 22 27 20.65625 27 19 C 27 17.34375 28.34375 16 30 16 C 31.65625 16 33 17.34375 33 19 "></path>
                    <path fill="#FFA000" d="M 45 19 C 45 20.65625 43.65625 22 42 22 C 40.34375 22 39 20.65625 39 19 C 39 17.34375 40.34375 16 42 16 Z "></path>
                    <path fill="#FFA000" d="M 15 19 C 15 20.65625 16.34375 22 18 22 C 19.65625 22 21 20.65625 21 19 C 21 17.34375 19.65625 16 18 16 C 16.34375 16 15 17.34375 15 19 "></path>
                    <path fill="#FFA000" d="M 3 19 C 3 20.65625 4.34375 22 6 22 C 7.65625 22 9 20.65625 9 19 C 9 17.34375 7.65625 16 6 16 Z "></path>
                    <path fill="#FFC107" d="M 32 11 L 27 11 L 27 19 L 33 19 Z "></path>
                    <path fill="#FFC107" d="M 42 11 L 37 11 L 39 19 L 45 19 Z "></path>
                    <path fill="#FFC107" d="M 16 11 L 21 11 L 21 19 L 15 19 Z "></path>
                    <path fill="#FFC107" d="M 6 11 L 11 11 L 9 19 L 3 19 Z "></path>
                    <path fill="#1976D2" d="M 23 25 L 25 25 L 25 42 L 23 42 Z "></path>
                </g>
            </svg> 
        </div>
    )
}

export default StoreIcon