import './auth.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { login } from './actions'
import LoginForm from './components/login.form'
import { Card } from 'antd'

export class Auth extends Component {
    render() {
        const { login, loadingUser } = this.props

        return (
            <div className="auth">
                <Card bordered={false}>
                    <LoginForm login={login} loadingUser={loadingUser} />
                </Card>
            </div>
        )
    }
}

const mapState = state => ({
    loadingUser: state.loading.loadingUser
})

const actions = { login }

export default connect(mapState, actions)(Auth)
