const processItems = (type, items) => {
    if (!items || items.length === 0) return
    const combined = items.reduce((a, b) => { return { items: a.items.concat(b.items) } })
    const itemsArray = combined.items.filter(item => item.type === type)
    let itemsObject
    if (type === 'collection') {
        // Sort by ID
        itemsObject = itemsArray.reduce((items, item) => Object.assign(items, { [item.id]: item }), {}) 
    } else {
        // Sort by name
        itemsObject = itemsArray.reduce((items, item) => Object.assign(items, { [item.name]: item }), {}) 
    }
    return itemsObject
}

export default processItems