import React from 'react'

const OptionsIcon = () => {
    return (
        <svg width="20px" height="20px" viewBox="0 0 172 172">
            <path fill="darkgray" d="M103.91667,25.08333c0,10.03613 -7.88053,17.91667 -17.91667,17.91667c-10.03613,0 -17.91667,-7.88053 -17.91667,-17.91667c0,-10.03613 7.88053,-17.91667 17.91667,-17.91667c10.03613,0 17.91667,7.88053 17.91667,17.91667zM86,68.08333c-10.03613,0 -17.91667,7.88053 -17.91667,17.91667c0,10.03613 7.88053,17.91667 17.91667,17.91667c10.03613,0 17.91667,-7.88053 17.91667,-17.91667c0,-10.03613 -7.88053,-17.91667 -17.91667,-17.91667zM86,129c-10.03613,0 -17.91667,7.88053 -17.91667,17.91667c0,10.03613 7.88053,17.91667 17.91667,17.91667c10.03613,0 17.91667,-7.88053 17.91667,-17.91667c0,-10.03613 -7.88053,-17.91667 -17.91667,-17.91667z"></path>
        </svg>
    )
}

export default OptionsIcon