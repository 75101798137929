// Ref: https://stackoverflow.com/questions/2811165/javascript-points-calculating-system

export const getPoints = (level) => {
    return 20 * (level - 1) * (level + 3) // Total points needed for the level
}

export const getLevel = (points) => {
    const level = -1 + Math.sqrt(4 + points / 20);
    return Math.floor(level)
}

export const getPercentage = (points) => {
    const level = getLevel(points)
    const pointsForLevel = getPoints(level + 1) - getPoints(level) 
    const pointsSoFar = points - getPoints(level)
    
    return (pointsSoFar / pointsForLevel).toFixed(2)
}

export const getPointsMore = (points) => {
    const level = getLevel(points)
    const pointsForLevel = getPoints(level + 1) - getPoints(level) 
    const pointsSoFar = points - getPoints(level)

    return pointsForLevel - pointsSoFar
}