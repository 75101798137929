import React from 'react'
import { Tooltip } from 'antd'

const Collections = (props) => {
    let collections = []
    let fillings = []

    for (let itemId in props.userItems) {
        let item = props.items[itemId]
        if (item) {
            let collection = {
                name: item.name,
                description: item.description,
                source: item.source,
                count: props.userItems[itemId].count
            }
            collections.push(collection)
        }
    }

    let vacancies = collections.length > 16 ? 0 : 16 - collections.length
    for (let filling = 0; filling < vacancies; filling++) fillings.push({})

    return (
        <div className="collections">
            <div className="items">
                {collections.map((item, index) => {
                    return (
                        <div className="item" key={index}>
                            <Tooltip title={`${item.name} × ${item.count}`}>
                                <img className="item-picture" width="50px" height="50px" alt="item" src={item.source} />
                            </Tooltip>
                        </div>
                    )
                })}
                {fillings.map((item, index) => {
                    return (
                        <div className="item-picture" key={index} />
                    )
                })}
                <div className="items-label">YOUR COLLECTIONS</div>
            </div>
        </div>
    )
}

export default Collections