const prod = {
    API_KEY: 'AIzaSyChk8PxRv5QSyHc0n0e1eSltCauxf153Wk',
    AUTH_DOMAIN: 'walnut-prod.firebaseapp.com',
    DATABASE_URL: 'https://walnut-prod.firebaseio.com"',
    PROJECT_ID: 'walnut-prod',
    STORAGE_BUCKET: 'walnut-prod.appspot.com',
    MESSAGING_SENDER_ID: '865258633426'
}

const dev = {
    API_KEY: 'AIzaSyBSeimwYEPDMpONPAHvQF1bOw20OkaMLV8',
    AUTH_DOMAIN: 'walnut-dev.firebaseapp.com',
    DATABASE_URL: 'https://walnut-dev.firebaseio.com',
    PROJECT_ID: 'walnut-dev',
    STORAGE_BUCKET: 'walnut-dev.appspot.com',
    MESSAGING_SENDER_ID: '45399991695'
}

const keys = process.env.NODE_ENV === 'production' ? prod : dev

export default keys