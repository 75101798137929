const LOAD_USER = 'LOAD_USER'
const LOADED_USER = 'LOADED_USER'
const LOAD_AVATAR = 'LOAD_AVATAR'
const LOADED_AVATAR = 'LOADED_AVATAR'
const LOAD_FOLLOWINGS = 'LOAD_FOLLOWINGS'
const LOADED_FOLLOWINGS = 'LOADED_FOLLOWINGS'

const initialState = {
    loadingUser: false,
    loadingAvatar: false,
    loadingFollowings: false
}

export const loadUser = () => ({ type: LOAD_USER })
export const loadedUser = () => ({ type: LOADED_USER })
export const loadAvatar = () => ({ type: LOAD_AVATAR })
export const loadedAvatar = () => ({ type: LOADED_AVATAR })
export const loadFollowings = () => ({ type: LOAD_FOLLOWINGS })
export const loadedFollowings = () => ({ type: LOADED_FOLLOWINGS })

export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_USER:
            return Object.assign({}, state, {
                loadingUser: true
            })
        case LOADED_USER:
            return Object.assign({}, state, {
                loadingUser: false,
            })
        case LOAD_AVATAR:
            return Object.assign({}, state, {
                loadingAvatar: true
            })
        case LOADED_AVATAR:
            return Object.assign({}, state, {
                loadingAvatar: false,
            })
        case LOAD_FOLLOWINGS:
            return Object.assign({}, state, {
                loadingFollowings: true
            })
        case LOADED_FOLLOWINGS:
            return Object.assign({}, state, {
                loadingFollowings: false,
            })
        default:
            return state
    }
}