import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { render } from '../../common/tools/form'
import Button from '../../common/components/button'
import { Form, Icon, Input, Alert } from 'antd'
const InputField = render(Input)

class LoginForm extends Component {
    render() {
        const { login, error, handleSubmit, loadingUser } = this.props
        
        return (
            <Form onSubmit={handleSubmit(login)}>
                {error && <Alert className="error" type="warning" message={error} showIcon />}
                <Field
                    name="email"
                    type="email"
                    component={InputField}
                    prefix={<Icon type="mail" />}
                    placeholder="Your email"
                    size="large"
                    autoFocus
                />
                <Field
                    name="password"
                    type="password"
                    component={InputField}
                    prefix={<Icon type="lock" />}
                    placeholder="Your password"
                    size="large"
                />
                <div className="login-button">
                    <Button 
                        type="white" 
                        loading={loadingUser} 
                        htmlType="submit"
                        size="normal">
                        LOGIN
                    </Button>
                </div>
            </Form>
        )
    }
}

export default reduxForm({ form: 'loginForm' })(LoginForm)