const LOAD_QUEUE = 'LOAD_QUEUE'

const initialState = {
    queue: []
}

export const loadQueue = queue => ({ type: LOAD_QUEUE, queue })

export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_QUEUE:
            return {
                queue: action.queue
            }
        default:
            return state
    }
}