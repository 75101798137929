import React from 'react'
import WordLogo from './word'

const Loading = ({ pastDelay, error }) => {
    if (pastDelay) {
        return <div className="word-logo loading"><WordLogo /></div>
    }
    else if (error) {
        return <div className="error-state"><h1>Sorry, there was a problem loading the page.</h1></div>
    }
    else {
        return null
    }
}

export default Loading