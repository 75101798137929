import React from 'react'
import { Link } from 'react-router-dom'
import OptionsIcon from './options.icon'
import { Dropdown, Menu } from 'antd'

const Options = props => {
    return (
        <div className="options">
            <Dropdown placement="bottomRight" trigger={['click']} overlay={
                <Menu>
                    <Menu.Item>
                        <Link className="header-option" to='/avatar'>My Avatar</Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link className="header-option" to={'/customize'}>Customize</Link>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item>
                        <Link className="header-option" to='/store'>Store</Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link className="header-option" to='/search'>Search</Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link className="header-option" to={'/completions'}>Completions</Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link className="header-option" to='/settings'>Settings</Link>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item onClick={props.signout}>
                        Logout
                    </Menu.Item>
                </Menu>}>
                <div className="options-button">
                    <OptionsIcon />
                </div>
            </Dropdown>
        </div>
    )
}

export default Options